const receiptData = {
    siteInfo: {
        siteName: "Annes Hårdesign Laksevåg",
        address: "Kringsjåveien 83",
        postArea: "5163 laksevåg",
        phoneNumber: "55346001",
        organisationNumber: "Orgnr: 983456642",
        webPage: "www.anneshaardesign.no",
        hasBonusPointsEnabled: true,
        bottomTexts: [
            "Besøk oss gjerne igjen!"
        ]
    },
    visitInfo: [
        {
            customerName: "Remzo Alibasic",
            employeeName: "Kjersti S.",
            salesLine: [
                {
                    quantity: 1,
                    description: "Vask m/ tørk i hjelm",
                    employeeInitials: "KE ",
                    salesPrice: 165,
                    taxPercent: 25,
                    discounts: [],
                    bonusPointsUsed: 10,
                    productSerie: ""
                },
                {
                    quantity: 1,
                    description: "Lugg                ",
                    employeeInitials: "KE ",
                    salesPrice: 245,
                    taxPercent: 25,
                    discounts: [{
                        discountName: 'Rabatt på joker',
                        discountPercent: 18.33,
                        beforePrice: 300.00,
                        discountAmount: 55,
                        bonusWithdrawal: 0,
                        isCampaignDiscount: true
                    }],
                    bonusPointsUsed: 0,
                    productSerie: ""
                },
                {
                    quantity: 1,
                    description: "Vask/føhn/legg      ",
                    employeeInitials: "KE ",
                    salesPrice: 480,
                    taxPercent: 25,
                    discounts: [],
                    bonusPointsUsed: 0,
                    productSerie: ""
                },
                {
                    quantity: 1,
                    description: "Hårkrem",
                    employeeInitials: "KE ",
                    salesPrice: 200,
                    taxPercent: 25,
                    discounts: [],
                    bonusPointsUsed: 0,
                    productSerie: "wella"
                }
            ],
            receiptPayment: {
                totalPrice: 890,
                totalClaimsPayed: 0,
                totalTax: 178,
                taxInfo: [
                    {
                        totalWithTax: 50,
                        taxPercent: 0,
                        taxAmount: 0,
                        totalWithoutTax: 50
                    }
                ]
            },
            claims: [{
                description: "dunno",
                employeeInitials: "ks",
                amount: 20
            }],
            bonusPointsAvailable: 38,
            bonusPointsEarned: 7,
            bonusPointsUsed: 10,
            donations: [
                {
                    title: "Redd kjetil",
                    employeeInitials: "",
                    salesPrice: 20.00,
                    subTitle: ""
            }]
        }
    ],
    dateAndTime: "2015-12-11T12:59:03",
    voucherNumber: 10,
    cash: 860,
    debitCard: 10,
    creditCard: 10,
    credit: 10,
    giftCertificates: [
        {
            giftCertificateNumber: 1000,
            amount: 300
        }
    ],
    moneyBack: 10,
    roundedAmount: 10,
    chainId: 694,
    customerId: "BD74XyR@uBIUxIHP",
    mobile: "92247992",
    systemId: "b501-234-bx",
    giftcardBought: [
    {
        giftCertificateNumber: 1001,
        amount: 100,
        physicalGiftcardId: null
    }
    ],
    usedIntegratedTermianl: true,
    terminalPayments: [{
        cardType: "debit",
        amount: 10,
        termianlReceipt: "",
        gavekortId: "1100",
        physicalGiftcardId: "",
        externalGiftcardId:""
    }],
    giro: 100

}

export default receiptData