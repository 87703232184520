
export function menuItemsList () {
    return [
        {
            Caption: "Artikler",
            Id: 1,
            Link: "/news",
            OpenInNewWindow: false,
            isInternal: true,
            Visible: true,
            Type: 1,
            Dialog: false,
            DialogVariable: false,
        },
        {
            Caption: "Behandlinger",
            Id: 2,
            Link: "/behandlinger",
            OpenInNewWindow: false,
            isInternal: true,
            Visible: true,
            Type: 1,
            Dialog: false,
            DialogVariable: false,
        },
        {
            Caption: "Gavekort",
            Id: 3,
            Link: `/giftcard`,
            OpenInNewWindow: false,
            isInternal: true,
            Visible: true,
            Type: 1,
            Dialog: false,
            DialogVariable: false,
        },
        {
            Caption: "Nettbutikk",
            Id: 4,
            Link: "",
            OpenInNewWindow: false,
            isInternal: false,
            Visible: false,
            Type: 1,
            Dialog: false,
            DialogVariable: false,
        },
        {
            Caption: "Om oss",
            Id: 6,
            Link: "/about",
            OpenInNewWindow: false,
            isInternal: true,
            Visible: true,
            Type: 1,
            Dialog: false,
            DialogVariable: false,
        },
        {
            Caption: "Bestill time",
            Id: 7,
            OpenInNewWindow: true,
            isInternal: false,
            Visible: true,
            Type: 2,
            Dialog: false,
            DialogVariable: false,
        },
    ]
}
