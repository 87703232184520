import PopularProductsService from "../../services/popularProductsService"

export const state = () => ({
    products: [],
})
const getters = {
    getProducts: state => state.products,
}

const mutations = {
    setProducts(state, value) {
        state.products = value
    },
}
const actions = {
    async fetchPopularProducts({commit, rootGetters}) {
        const productsService = new PopularProductsService({axios: this.$axios, env: this.$config});
        const chainId = rootGetters['cmsConfigHomepage/getChainId'];
        const siteIds = rootGetters['salon/getSiteIds'];
        let result = await productsService.GetPopularWebshopProductsForSiteList(chainId, siteIds, 5, 200)
        if(result) {
            commit('setProducts', result)
            return true
        }
        return false
    },
}

export default {
    namespaced: true,
    getters,
    state,
    actions,
    mutations
}
