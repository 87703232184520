export default function InstagramService(options) {
    this.axios = options.axios
        .create({
            baseURL: options.env.HOMEPAGE_CORE_API
        });

    /**
	 * Get latest instagram images for departments.
     * @param {Array<Int>} siteIds - the departments for which the products will be retrieved from
	 */
    this.GetLatestInstagramImages = async function(siteIds) {
        const result = await this.axios.post(`instagram/get-latest-instagram-images`, siteIds);
        if (result.data.responseCode === 200) {
			return result.data.result;
		}

		return false;
	}

}
