import SalonService from "../../services/salonService";

export const state = () => ({
  salons: [],
});
const getters = {
  getSalons: (state) => state.salons,
  getSiteIds: (state) => state.salons.map((x) => x.siteId),
  getSalonById: (state) => (id) =>
    state.salons.find((salon) => salon.siteId === id),
  getHideEmployees: (state) => (id) =>
    state.salons.find((salon) => salon.siteId === id).hideEmployees,
  getSalonByUrlFriendlyLink: (state) => (id) =>
    state.salons.find((salon) => salon.urlFrendlyLink === id),
  getSalonPhoneNumber: (state) => (id) =>
    state.salons.find((salon) => salon.siteId === id),
};

const mutations = {
  setSalons(state, value) {
    state.salons = value;
  },
};
const actions = {
  async fetchSalons({ commit, rootGetters }) {
    const salonService = new SalonService({
      axios: this.$axios,
      env: this.$config,
    });
    const homepageId = rootGetters["cmsConfigHomepage/getHomepageId"];
    if (homepageId !== null) {
      let result = await salonService.GetSalonsByHomepageId(homepageId);
      commit("setSalons", result);
      return true;
    }
    return false;
  },
};

export default {
  namespaced: true,
  getters,
  state,
  actions,
  mutations,
};
