
export default {
  name: "chooseSalonDialog",
  props: {
    treatment: {
        required: false,
        default: null,
    },
    title: {
        required: false,
    },
    bonusPage: {
        required: false,
        default: false,
    }
  },
  data: () => ({
    dialog: false,
  }),
  methods: {
    price(siteId) {
      return this.treatment.pricePerSite.find(doc => doc.siteId == siteId).price
    },
    redirect(site) {
        if (this.appointmentButtonOverrideUrl) {
            if (this.appointmentButtonOverrideUrl != '') {
                window.open(this.appointmentButtonOverrideUrl, '_self')
            }
        } else {
            if (this.treatment) {
                if (this.bonusPage) {
                    window.open(`${this.$config.PORTAL_URL}/booking/${site.siteId}#/mypage/visits`)
                } else {
                    let routeDataTreatment = this.$router.resolve({name: 'redirect', path: `${this.$config.PORTAL_URL}/frisor/${site.urlLink}/${site.siteId}`, query: {'treatmentIds': [`${btoa(this.treatment.treatmentId)}`]}});
                    window.open(routeDataTreatment.href, '_blank');
                }
            } else {
                if (this.bonusPage) {
                    window.open(`${this.$config.PORTAL_URL}/booking/${site.siteId}#/mypage/visits`)
                } else {
                    let routeDataBanner = this.$router.resolve({name: 'redirect', path: `${this.$config.PORTAL_URL}/frisor/${site.urlLink}/${site.siteId}`});
                    window.open(routeDataBanner.href, '_blank');
                }
            }
        }
    },
    openDialog() {
        this.dialog = !this.dialog
    }
  },
  computed: {
    salons() {
      let sites = []
      let siteIds = this.$store.getters['salon/getSiteIds']
      siteIds.forEach(siteId => {
          if (this.treatment) {
              this.treatment.pricePerSite.forEach(priceSite => {
                if (priceSite.siteId == siteId) sites.push(this.$store.getters['salon/getSalonById'](siteId))
              })
          } else {
              sites.push(this.$store.getters['salon/getSalonById'](siteId))
          }
      })
      return sites
    },
    appointmentButtonOverrideUrl() {
      return this.$store.getters["cmsConfigHomepage/getAppointmentButtonOverrideUrl"];
    },
    isDraft() {
      return this.$store.getters['cmsConfigHomepage/isDraftMode']
    }
  },
//   mounted() {
//     this.$root.$on('openSalonDialog', () => {
//         this.openDialog()
//       })
//   },
    created() {
        this.$nuxt.$on('openSalonDialog', () => {
            if (this.salons.length > 1) this.openDialog()
            else {
                let routeDataBanner = this.$router.resolve({name: 'redirect', path: `${this.$config.PORTAL_URL}/frisor/${this.salons[0].urlLink}/${this.salons[0].siteId}`});
                window.open(routeDataBanner.href, '_blank');
            }
   		})
    }
};
