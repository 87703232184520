export default function PopularProductsService(options) {
    this.axios = options.axios
    .create({
        baseURL: options.env.HOMEPAGE_CORE_API
    });
    /**
	 * Get popularProducts url.
	 * @param {Int} homepageId - the chain Id
     * @param {Array<Int>} siteIds - the departments for which the products will be retrieved from
     * @param {int} limit - number of products to be retrived
     * @param {int} daysOffset - timespan of popular relevance
	 */
    this.GetPopularWebshopProductsForSiteList = async function(chainId, siteIds, limit, daysOffset) {
		const result = await this.axios.post('product/get-popular-webshop-products-for-sitelist', {
                chainId: chainId,
                siteIds: siteIds,
                limit: limit,
                daysOffset: daysOffset

        });
		if (result.data.responseCode === 200) {
			return result.data.result;
		}
		return false;
	}

}
