import TreatmentService from "../../services/treatmentsService"
import ProfileSvc from '@fixit/fixit-no-header/src/services/profile-svc'
import _ from 'lodash-es';

export const state = () => ({
    treatmentCategoryList: [],
    popularTreatments: [],
    treatments: [],
    numberOfTreatments: 0,
    favoriteTreatments: [],
})
const getters = {
    getTreatmentCategoryList: state => state.treatmentCategoryList,
    getCategory: state => id => state.treatmentCategoryList.find(category => category.treatmentCategoryId === id),
    getTreatments: state => state.treatments,
    getTreatmentItemById: state => (treatmentId, categoryId) => state.treatments.find(treatment => treatment.urlSafe === treatmentId),
    getRelatedTreatmentsByIdList: state => ids => {
        var relatedTreatments = []
        state.treatments.foreach(treatment => {
          treatment.relatedTreatments.forEach(id => {
            let relatedTreatment = state.treatments.find(treatment => treatment.treatmentId === id)
            relatedTreatments.push(relatedTreatment)
          })
        })
        return relatedTreatments
    },
    getPopularTreatments: state => {
        // var popularTreatmentsMixed = []
        // state.popularTreatments.forEach(site => {
        //   site.treatments.forEach(treatment => {
        //     popularTreatmentsMixed.push(treatment)
        //   })
        // })
        return state.popularTreatments
    },
    getPopularTreatmentsBySiteId: state => id => state.popularTreatments.find(x => x.treatmentCategoryList.find(z => z.siteId == id)),
    numberOfTreatments: state => state.numberOfTreatments,
    getFavoriteTreatments: state => state.favoriteTreatments

}

const mutations = {
    setTreatmentCategoryList(state, value) {
        state.treatmentCategoryList = value
    },
    setTreatments(state, value) {
        state.treatments = value.listOfTreatments || []
        state.numberOfTreatments = value.numberOfItems || 0
    },
    setPopularTreatments(state, value) {
        state.popularTreatments = value
    },
    resetPopularTreatments(state) {
        state.popularTreatments = []
    },
    setFavoriteTreatments(state, value) {
        state.favoriteTreatments = value
    }
}
const actions = {
    resetPopularTreatmentsState({commit}) {
        commit('resetPopularTreatments')
    },
    async fetchTreatments({commit, rootGetters}, filter) {
     const treatmentService = new TreatmentService({axios: this.$axios, env: this.$config});
     if(filter !== null){
        let result = await treatmentService.GetTreatmentsBySiteIds(filter)
        if(result){
            commit('setTreatments', result)
            return true
        }
     }
     return false
    },
    async fetchCategories({commit, rootGetters}) {
        const treatmentService = new TreatmentService({axios: this.$axios, env: this.$config});
        const chainId = rootGetters['cmsConfigHomepage/getChainId'];
        const siteIds = rootGetters['salon/getSiteIds'];
        if(chainId !== null){
           let result = await treatmentService.GetTreatmentCategories(chainId, siteIds)
           if(result){
               commit('setTreatmentCategoryList', result)
               return true
           }
        }
        return false
       },
    async fetchPopularTreatments({commit, rootGetters}, siteIds) {
     const treatmentService = new TreatmentService({axios: this.$axios, env: this.$config});
     if (siteIds == null) siteIds = rootGetters['salon/getSiteIds'];
     const chainId = rootGetters['cmsConfigHomepage/getChainId'];
     if(siteIds !== null && chainId !== null){
        let result = await treatmentService.GetPopularTreatmentsBySiteIds(siteIds, chainId)

        if(result){
            commit('setPopularTreatments', result)
            return true
        }
     }
     return false
    },
    async fetchTreatment({commit, rootGetters}, id){
      const treatmentService = new TreatmentService({axios: this.$axios, env: this.$config});
      let result = null
      if(id != null){
        result = await treatmentService.GetTreatment(id, rootGetters['cmsConfigHomepage/getChainId'] ,rootGetters["cmsConfigHomepage/getSiteIds"])
      }

      return result;
    },
    async fetchFavoriteTreatments({commit, rootGetters}) {
        const service = await ProfileSvc(this.$axios)
        var siteIds = rootGetters['salon/getSiteIds'];
        var result = await service.GetFavoriteTreatments(this.$config.VUE_APP_FIXIT_NO_BOOKING_URL, siteIds)
        commit('setFavoriteTreatments', result)
    },
    async updateFavoriteTreatments({commit, rootGetters}, treatment) {
        const service = await ProfileSvc(this.$axios)
        var siteIds = rootGetters['salon/getSiteIds'];
        return await service.UpdateFavoriteTreatments(
            this.$config.VUE_APP_FIXIT_NO_BOOKING_URL, 
            treatment.treatmentId, 
            siteIds[0].toString()
        )
    }
}

export default {
    namespaced: true,
    getters,
    state,
    actions,
    mutations
}
