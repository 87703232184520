
import { menuItemsList } from "~/static/menuItems";
import { SettingTypeEnum } from "~/static/enums/index.js";
import { waitFor } from "vue-wait";
import HomepageFooter from "~/components/HomepageFooter.vue";

import {
  TheFixitNoHeader,
  TheFixitNoSnackbar,
} from "@fixit/fixit-no-header";
import { mapWaitingActions } from "vue-wait";
import { Header,Notification, User } from "@fixit/fixit-no-header/src/models";
import {createOpacityVariables} from '@fixit/fixit-no-header/src/utils/opacity-colors'


export default {
  name: "HomepageLayout",
  head() {
    return {
      link: [
        {
          rel: "icon",
          type: "image/x-icon",
          href: this.$store.getters["cmsConfigHomepage/getLogo"],
        },
      ],
    };
  },
  components: {
    TheFixitNoHeader,
    TheFixitNoSnackbar,
    HomepageFooter,
  },
  data() {
    return {
      atTop: true,
      height: 60,
      overrideOptionsBooking: {},
      snackbarState: new Notification(),
      primary: null,
      secondary: null,
      primaryText: null,
      secondaryText: null,
      showHeaderAndFooter: false
    };
  },
  computed: {
    salons() {
      let sites = [];
      let siteIds = this.$store.getters["salon/getSiteIds"];
      siteIds.forEach((siteId) => {
        sites.push(this.$store.getters["salon/getSalonById"](siteId));
      });
      return sites;
    },
    header() {
      let header = new Header({
        height: 60,
        elevation: 3,
        apiUrl: this.$config.FIXIT_CORE_API_URL,
        oauthUrl: this.$config.VUE_APP_OAUTH_URL,
        fixitNoUrl: this.$config.PORTAL_URL,
        useFixitOauthLogin: true,
        items: menuItemsList().filter((x) => {
          if (x.Caption === "Artikler") {
            x.Visible = this.$store.getters["cmsConfigHomepage/newsVisible"] && this.$store.getters["news/getNews"].length;
          }
          if (x.Caption === "Nettbutikk") {
            x.Visible = this.$store.getters["cmsConfigHomepage/getWebshopActiveStatus"];
            x.Link = `/nettbutikk/${this.$store.getters['cmsConfigHomepage/getChainId']}`
          }
          if (x.Caption === "Gavekort") {
            x.Visible = this.$store.getters["cmsConfigHomepage/getGiftcardActiveStatus"];
          }
          return x;
        }),
        layout: "chain",
        atTop: false,
        headerColors: [this.colorSecondary, this.colorSecondary],
        sites: this.salons,
        draftMode: this.$store.getters["cmsConfigHomepage/isDraftMode"],
      });
      return header;
    },
    homepageLogo() {
      return this.$store.getters["cmsConfigHomepage/getLogo"];
    },
    menuItems() {
      return menuItemsList();
    },
    snackbar() {
      return this.snackbarState;
    },
    user() {
      return new User(this.$store.getters["app/get"]("user"));
    },
    colorPrimary() {
      return this.primary;
    },
    colorSecondary() {
      return this.secondary;
    },
    primaryTextColor() {
      return this.primaryText;
    },
    secondaryTextColor() {
      return this.secondaryText;
    },
    chainLogo() {
      return this.$store.getters["cmsConfigHomepage/getLogo"];
    },
    homepageId() {
      return this.$store.getters["cmsConfigHomepage/getHomepageId"]
    },
  },
  mounted() {
    if (process.client) {
      this.showHeaderAndFooter = !window.location.pathname.includes('receipt') || (window.location.pathname.includes('receipt') && !window.location.host.includes('receipt.onlinetimebok.no'))
    }
    this.onScroll();
    var settings = this.$store.getters["cmsConfigHomepage/getTemplateSetting"];
    if (settings) {
      var font = settings?.find(
        (t) => t.settingType == SettingTypeEnum.FONT1
      )?.settingValue;
      var font2 = settings?.find(
        (t) => t.settingType == SettingTypeEnum.FONT2
      )?.settingValue;

      this.createElement("primary-font", "font-family", font2);
      this.createElement("secondary-font", "font-family", font);
    }

    if (!this.$store.getters['cmsConfigHomepage/getCmsFound'] && !window.location.host.includes('receipt.onlinetimebok.no')) {
      window.open(`${this.$config.PORTAL_URL}`, '_self');
    }
  },
  created() {
    var settings = this.$store.getters["cmsConfigHomepage/getTemplateSetting"];
    //change back after demo
    this.primary = settings?.find(
      (t) => t.settingType == SettingTypeEnum.COLOR1
    )?.settingValue;
    this.secondary = settings?.find(
      (t) => t.settingType == SettingTypeEnum.COLOR2
    )?.settingValue;

    createOpacityVariables(this.primary, this.secondary)
    if (settings) {
      this.primaryText = this.GetReadableForeColorHex(this.primary);
      this.secondaryText = this.GetReadableForeColorHex(this.secondary);
    }
    if (typeof window !== "undefined") {
      window.addEventListener("scroll", this.onScroll);
    }
  },

  methods: {
    toggleLoginDialog() {
      if (this.$refs.fixitNoHeader) this.$refs.fixitNoHeader.openDialog()
    },
    ...mapWaitingActions("app", {
      getCurrentUser: "loading initailize homepage getCurrentUser",
    }),
    navigationAction(nav) {
      if (this.$nuxt.$router.path !== nav.Link) this.$router.push(nav.Link);
    },
    GetReadableForeColorHex(hex) {
      var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
      var hex = result
        ? {
            r: parseInt(result[1], 16),
            g: parseInt(result[2], 16),
            b: parseInt(result[3], 16),
          }
        : null;
      return (hex.r + hex.b + hex.g) / 3 < 128 ? "#FFFFFF" : "#000000";
    },
    createElement(className, prop, value) {
      var style = document.createElement("style");
      document.head.appendChild(style);
      style.sheet.insertRule(
        "." + className + "{" + prop + ":" + value + "!important" + "}"
      );
    },
    onScroll() {
      this.atTop =
        (window.pageYOffset ||
          document.documentElement.scrollTop ||
          document.body.scrollTop ||
          0) === 0;
    },
    setActiveUser(user) {
      if (user.onlineSluttKundeId != 0) {
        this.$store.dispatch('employees/fetchFavoriteEmployees')
        this.$store.dispatch('treatments/fetchFavoriteTreatments')
      } else {
        this.$store.commit("employees/setFavoriteEmployees", []);
        this.$store.commit("treatments/setFavoriteTreatments", []);
      }
      this.$store.commit("app/SET_USER", user);
    },
    setSnackbar(snackbar) {
      this.snackbarState = new Notification(snackbar);
    },
    closeSnackbar(snackbar) {
      this.snackbarState = new Notification(snackbar);
    },
    updateTreatments() {
      this.$store.dispatch('treatments/fetchFavoriteTreatments')
    },
    updateEmployees() {
      this.$store.dispatch('employees/fetchFavoriteEmployees')
    },
    goHome() {
      this.$router.replace("/");
    },
  },

  watch: {
    $route(newValue, oldValue) {
      if (Object.keys(oldValue.query).length != 0 && this.header.draftMode) {
        this.$router.push({query: {...JSON.parse(JSON.stringify(newValue.query)), homepageId: this.homepageId}})
      }
    }
  }
};
