// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
___CSS_LOADER_EXPORT___.push([module.id, "@import url(https://cdn.fixitonline.no/assets/public/test/styles/fonts.css);"]);
___CSS_LOADER_EXPORT___.push([module.id, "@import url(https://cdn.fixitonline.no/assets/public/test/fixit-icons/style.css);"]);
___CSS_LOADER_EXPORT___.push([module.id, "@import url(https://fonts.googleapis.com/css2?family=Architects+Daughter&family=Calligraffitti&family=Covered+By+Your+Grace&family=Dosis:wght@300;400;500;600;700&family=Indie+Flower&family=Josefin+Sans:wght@300;400;500;600;700&family=News+Cycle:wght@400;700&family=Nothing+You+Could+Do&family=Open+Sans:wght@300;400;500;600;700&family=Quicksand:wght@300;400;500;600;700&family=Raleway:wght@300;400;500;600;700&family=Shadows+Into+Light&family=Shadows+Into+Light+Two&family=Source+Sans+Pro:wght@300;400;600;700&display=swap);"]);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".v-application{-webkit-print-color-adjust:exact}.header-logo{-o-object-fit:contain;object-fit:contain}.header-logo:hover{cursor:pointer}@media only screen and (min-width:960px){::-webkit-scrollbar{height:10px;width:10px}::-webkit-scrollbar-track{background:#fff}::-webkit-scrollbar-track-piece{background:rgba(66,190,180,.1)}::-webkit-scrollbar-thumb{background:var(--v-primary-lighten1)}::-webkit-scrollbar-thumb:hover{background:var(--v-primary-lighten2)!important;cursor:pointer;transform:scale(1.1)}}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
module.exports = ___CSS_LOADER_EXPORT___;
