
import FIcon from '@fixit/icons/src/components/FIcon.vue'
export default {
  components: { FIcon },
  props: {
    breadcrumbs: {
        type: Array,
        default: null,
        required: true,
    }
  },
};
