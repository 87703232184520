import axios from "axios";
import { axiosRefreshTokenSetup } from "@fixit/utils/src/axiosHandlers";


export default function ({ $config }, inject) {
  var config = $config;
  if(process.client){
    axiosRefreshTokenSetup(axios, 'noredirect', `/refresh-token`);
    axios.defaults.withCredentials = false;
  }

  inject('axios', axios)
}

