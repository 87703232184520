import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _45e1d530 = () => interopDefault(import('..\\client\\pages\\about\\index.vue' /* webpackChunkName: "pages/about/index" */))
const _f328add8 = () => interopDefault(import('..\\client\\pages\\app\\index.vue' /* webpackChunkName: "pages/app/index" */))
const _1419506a = () => interopDefault(import('..\\client\\pages\\behandlinger\\index.vue' /* webpackChunkName: "pages/behandlinger/index" */))
const _6535e996 = () => interopDefault(import('..\\client\\pages\\bonus\\index.vue' /* webpackChunkName: "pages/bonus/index" */))
const _5f2e8266 = () => interopDefault(import('..\\client\\pages\\cookies\\index.vue' /* webpackChunkName: "pages/cookies/index" */))
const _56d1229f = () => interopDefault(import('..\\client\\pages\\giftcard\\index.vue' /* webpackChunkName: "pages/giftcard/index" */))
const _6ce846a8 = () => interopDefault(import('..\\client\\pages\\news\\index.vue' /* webpackChunkName: "pages/news/index" */))
const _56701f1d = () => interopDefault(import('..\\client\\pages\\receipt\\index.vue' /* webpackChunkName: "pages/receipt/index" */))
const _478e7a58 = () => interopDefault(import('..\\client\\pages\\receipt\\components\\ReceiptBottom.vue' /* webpackChunkName: "pages/receipt/components/ReceiptBottom" */))
const _48410c9b = () => interopDefault(import('..\\client\\pages\\receipt\\components\\ReceiptInfo.vue' /* webpackChunkName: "pages/receipt/components/ReceiptInfo" */))
const _6e5c062c = () => interopDefault(import('..\\client\\pages\\receipt\\components\\ReceiptPaymentMethod.vue' /* webpackChunkName: "pages/receipt/components/ReceiptPaymentMethod" */))
const _1bcce682 = () => interopDefault(import('..\\client\\pages\\receipt\\components\\ReceiptSiteInfo.vue' /* webpackChunkName: "pages/receipt/components/ReceiptSiteInfo" */))
const _ebd9826e = () => interopDefault(import('..\\client\\pages\\receipt\\components\\ReceiptSummary.vue' /* webpackChunkName: "pages/receipt/components/ReceiptSummary" */))
const _39a91e02 = () => interopDefault(import('..\\client\\pages\\receipt\\components\\TheReceipt.vue' /* webpackChunkName: "pages/receipt/components/TheReceipt" */))
const _522c1b76 = () => interopDefault(import('..\\client\\pages\\receipt\\data\\mock.js' /* webpackChunkName: "pages/receipt/data/mock" */))
const _9a1c9ec2 = () => interopDefault(import('..\\client\\pages\\index.vue' /* webpackChunkName: "pages/index" */))
const _7c6658b2 = () => interopDefault(import('..\\client\\pages\\about\\_id\\index.vue' /* webpackChunkName: "pages/about/_id/index" */))
const _14fdcbd8 = () => interopDefault(import('..\\client\\pages\\news\\_id.vue' /* webpackChunkName: "pages/news/_id" */))
const _fa003d16 = () => interopDefault(import('..\\client\\pages\\about\\_id\\_employee\\index.vue' /* webpackChunkName: "pages/about/_id/_employee/index" */))
const _5ab83ef9 = () => interopDefault(import('..\\client\\pages\\behandlinger\\_treatment\\_id.vue' /* webpackChunkName: "pages/behandlinger/_treatment/_id" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/about",
    component: _45e1d530,
    name: "about"
  }, {
    path: "/app",
    component: _f328add8,
    name: "app"
  }, {
    path: "/behandlinger",
    component: _1419506a,
    name: "behandlinger"
  }, {
    path: "/bonus",
    component: _6535e996,
    name: "bonus"
  }, {
    path: "/cookies",
    component: _5f2e8266,
    name: "cookies"
  }, {
    path: "/giftcard",
    component: _56d1229f,
    name: "giftcard"
  }, {
    path: "/news",
    component: _6ce846a8,
    name: "news"
  }, {
    path: "/receipt",
    component: _56701f1d,
    name: "receipt"
  }, {
    path: "/receipt/components/ReceiptBottom",
    component: _478e7a58,
    name: "receipt-components-ReceiptBottom"
  }, {
    path: "/receipt/components/ReceiptInfo",
    component: _48410c9b,
    name: "receipt-components-ReceiptInfo"
  }, {
    path: "/receipt/components/ReceiptPaymentMethod",
    component: _6e5c062c,
    name: "receipt-components-ReceiptPaymentMethod"
  }, {
    path: "/receipt/components/ReceiptSiteInfo",
    component: _1bcce682,
    name: "receipt-components-ReceiptSiteInfo"
  }, {
    path: "/receipt/components/ReceiptSummary",
    component: _ebd9826e,
    name: "receipt-components-ReceiptSummary"
  }, {
    path: "/receipt/components/TheReceipt",
    component: _39a91e02,
    name: "receipt-components-TheReceipt"
  }, {
    path: "/receipt/data/mock",
    component: _522c1b76,
    name: "receipt-data-mock"
  }, {
    path: "/",
    component: _9a1c9ec2,
    name: "index"
  }, {
    path: "/about/:id",
    component: _7c6658b2,
    name: "about-id"
  }, {
    path: "/news/:id",
    component: _14fdcbd8,
    name: "news-id"
  }, {
    path: "/about/:id/:employee",
    component: _fa003d16,
    name: "about-id-employee"
  }, {
    path: "/behandlinger/:treatment/:id?",
    component: _5ab83ef9,
    name: "behandlinger-treatment-id"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
