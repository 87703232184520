import { User } from '@fixit/fixit-no-header/src/models';
import CoreSvc from "../../services/fixitCoreSvc"

export const state = () => ({
    user: {}
})
const mutations = {
    SET_USER( state, value ) {
        state.user = value
    }
}
const getters = {
    get: state => key => state[key]
}

const actions = {
    async getCurrentUser( context ) {
        try{
            const coreService = new CoreSvc({env: this.$config});
            var user = await coreService.GetCurrentUser();
            if(user){
                context.commit('SET_USER', new User(user))
            }
        } catch(ex) {
            console.log("Getting user failed with: "+ex)
        }
        // context.dispatch('SET_USER', new User(user))
    }
}

export default {
    namespaced: true,
    state,
    mutations,
    getters,
    actions
}
