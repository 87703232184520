import InstagramService from "../../services/instagramService"
export const state = () => ({
    instagramImages: []
})
const getters = {
    getInstagramImages: state => state.instagramImages
}

const mutations = {
    setInstagramImages(state, value) {
        state.instagramImages = value
    }
}
const actions = {
    async fetchLastestInstagramImages( {commit, rootGetters} ) {
      var res = false
      try{
        const instagramService = new InstagramService({axios: this.$axios, env: this.$config})
        const siteIds = rootGetters['salon/getSiteIds'];
        if(siteIds.length > 0) {
            let result = await instagramService.GetLatestInstagramImages(siteIds)
            commit('setInstagramImages', result)
            res = true
        }
      } catch(ex) {
          console.log("Getting instagram images failed with: "+ ex)
      }

      return res
    }
}

export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions
}
