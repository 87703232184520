export default function CmsService(options) {
  this.axios = options.axios.create({
    baseURL: options.env.HOMEPAGE_CORE_API,
  });

  /**
   * Get chainConfig url.
   * @param {Int} homepageId - the chain Id
   * @param {Boolean} isDraft - Is draft
   */
  this.GetCmsConfigByHomepageId = async function (homepageId, isDraft) {
    const result = await this.axios.get(`cms/get-cms-config-by-homepageId`, {
      params: {
        homepageId: homepageId,
        isDraft: isDraft,
      },
    });
    if (result.data.result && result.data.responseCode === 200) {
      return result.data.result;
    }
    return false;
  };

  /**
   * Get cmsConfig.
   * @param {String} host - the homepage host
   * @param {Boolean} isDraft - Is draft
   */
  this.GetCmsConfigByHostName = async function (host, isDraft) {
    const result = await this.axios.get(`cms/get-cms-config-by-host`, {
      params: {
        host: host,
        isDraft: isDraft,
      },
    });
    if (result.data.result && result.data.responseCode === 200) {
      return result.data.result;
    }
    return false;
  };

  /**
   * Get chainConfig url.
   * @param {int} chainId - the chain Id
   */
  this.GetActiveCustomerPrograms = async function(chainId) {
    const result = await this.axios.get(`cms/get-active-customer-programs`, {
        params: {
            chainId: chainId,
        }
    });
    if(result.data.responseCode === 200) {
        return result.data.result
    }
    return false;
  }
}
