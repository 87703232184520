import Vue from 'vue'
import Vuex from 'vuex'
import employees from './modules/employees'
import salon from './modules/salon'
import cmsConfigHomepage from './modules/cmsConfigHomepage'
import news from './modules/news'
import popularProducts from './modules/popularProducts'
import treatments from './modules/treatments'
import receipt from './modules/receipt'
import instagramImages from './modules/instagramImages'
import app from './modules/app'
import consolaGlobalInstance from 'consola'

Vue.use(Vuex)

const createStore = () => {
  return new Vuex.Store({
    state: {},
    actions: {
      async nuxtServerInit({
        dispatch,
        getters
      }, context) {
        // pick up reverse proxy forwarded host from headers, else pick from config
        let host = context.req.headers['x-forwarded-host']; //|| context.$config.HOST
        if(host === undefined) host = ''
        let homepageId = null;
        if (context.req._parsedOriginalUrl?.query && context.req._parsedOriginalUrl?.query?.includes('homepageId')) {
          var queryWithHomepage = context.req._parsedOriginalUrl.query.includes('&') ? context.req._parsedOriginalUrl.query.split('&').find(q => q.includes('homepageId')) : context.req._parsedOriginalUrl.query;
          homepageId = parseInt(queryWithHomepage.split('=')[1]);
        }
        if (process.env.NODE_ENV === 'development' || process.env.NODE_ENV === 'dev')
          host = 'ramsvik.fixit-demo.no'
        try {
          if (homepageId || host !== '') {
            if(!host.includes('receipt.onlinetimebok.no')){
              !homepageId ? await dispatch('cmsConfigHomepage/fetchCmsConfigHomepage', host) : await dispatch('cmsConfigHomepage/fetchCmsConfigByHomepageId', homepageId);
              if (getters['cmsConfigHomepage/getHomepageId'] !== homepageId && (!getters['cmsConfigHomepage/getSiteUrl'].includes(host) || getters['cmsConfigHomepage/getSiteUrl'] === '')){
                context.error({ statusCode: 404, message: 'Problemer med å hente data' })
                this.replaceState()
              } else {
                await dispatch('salon/fetchSalons');
                await dispatch('cmsConfigHomepage/fetchActiveCustomerPrograms');
                if (getters['cmsConfigHomepage/newsVisible']) await dispatch('news/fetchNewsBySiteIds');
                console.log('Fetching page data complete');
                console.log('Initializing Homepage');
              }
            }
          } else {
            context.error({ statusCode: 404, message: 'Problemer med å hente data' })
            this.replaceState()
          }
          // await dispatch('cmsConfigHomepage/fetchChainNameAndLogo')
        } catch (err) {
          console.error('Fetching page data failed');
          console.error(err);
        }
      }
    },
    mutations: {},
    modules: {
      salon,
      cmsConfigHomepage,
      news,
      popularProducts,
      treatments,
      instagramImages,
      receipt,
      employees,
      app,
    }
  })
}

export default createStore
