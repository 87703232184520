import axios from "axios"

export default function CoreSvc(options) {
    this.baseUrl = options.env.FIXIT_CORE_API_URL
    /**
	 * Get current user based on JWT.
	 */
    this.GetCurrentUser = async function() {
			const result = await axios.get(this.baseUrl+`api/authorization/GetCurrentFixitNoUser`);
			if (result.data && result.data.responseCode === 200) {
				return result.data.dto;
			}

		return true;
	}
}

