import EmployeeService from '../../services/employeesService';
import ProfileSvc from '@fixit/fixit-no-header/src/services/profile-svc'
export const state = () => ({
    employees: [],
    favoriteEmployees: [],
})
const getters = {
    getEmployees: state => state.employees,
    getEmplyeeByTreatmentId: state => id => state.employees.find(employee => employee.treatments == id),
    //getLowestTreatmentPrice: state => id => state.employees.treatmentsWithPrice.find(treatments => ),
    getEmployeesBySiteId: state => siteId => state.employees.filter(employee => employee.siteId == siteId),
    getVisibleOnDigitalChannels: state => id => state.employees.find(employee => employee.employeeId == id).visibleOnDigitalChannels,
    getEmployeeByEmployeeWebId: state => id => state.employees.find(employee => employee.webId?.replace(/['"]+/g, '') == id),
    getEmployeeByEmployeeByFullName: state => fullName => state.employees.find(employee => employee.urlSafeName == fullName),
    getFavoriteEmployees: state => state.favoriteEmployees
}

const mutations = {
    setEmployees( state, value ) {
        state.employees = value;
    },
    resetEmployees(state) {
        state.employees = [];
    },
    setFavoriteEmployees(state, value) {
        state.favoriteEmployees = value
    }
}
const actions = {
    resetEmployeesState({commit}) {
        commit('resetEmployees');
    },

    async fetchEmployeesBySiteIds({commit, getters}, siteIds) {
        
        const employeeService = new EmployeeService({axios: this.$axios, env: this.$config});
        let result = await employeeService.GetEmployeesBySiteIds(siteIds, false)
        commit('setEmployees', result)

        return true;
    },
    async fetchFavoriteEmployees({commit, rootGetters}) {
        const service = await ProfileSvc(this.$axios)
        var siteIds = rootGetters['salon/getSiteIds'];
        var result = await service.GetFavoriteEmployees(this.$config.VUE_APP_FIXIT_NO_BOOKING_URL, siteIds)
        commit('setFavoriteEmployees', result)
    },
    async updateFavoriteEmployees({commit}, employee) {
        const service = await ProfileSvc(this.$axios)
        return await service.UpdateFavoriteEmployee(this.$config.VUE_APP_FIXIT_NO_BOOKING_URL, employee.employeeId)
    }
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}